export function trackEvent(
  name: string,
  category: string,
  label?: string,
  nonInteraction?: boolean,
) {
  // @ts-ignore
  window.ga &&
    // @ts-ignore
    window.ga('send', 'event', category, name, label, {
      nonInteraction: nonInteraction ? true : false,
    });
}
