import React from 'react';

export default function ({ isExtension }: { isExtension: boolean }) {
  return (
    <div className="flex justify-center opacity-50 hover:opacity-100 select-none text-sm py-2 animate-opacity">
      <a
        target="_blank"
        href="https://medium.com/@sharathprabhal/time-travel-convert-timezones-faster-than-ever-e9fd406467d2?source=friends_link&sk=dcd47ddda456e6677110835c01fa9344"
        rel="noopener noreferrer"
      >
        A Sharath Prabhal experiment
      </a>
    </div>
  );
}
