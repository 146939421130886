import React, { useState } from 'react';
import {
  FiMoon as DarkModeIcon,
  FiSun as LightModeIcon,
  FiHeart as HeartIcon,
  FiBookmark as BookmarkIcon,
} from 'react-icons/fi';
import { Theme, saveState, getState } from '../common/storage';

export default function ({ isExtension }: { isExtension: boolean }) {
  const state = getState();
  const [theme, setTheme] = useState(state.theme);

  const switchTheme = (theme: Theme) => {
    setTheme(theme);
    document.documentElement.setAttribute('theme', theme);
    saveState({
      theme,
    });
  };

  const themeButton =
    theme === Theme.light ? (
      <DarkModeIcon
        size="28"
        className="cursor-pointer text-accent"
        onClick={() => switchTheme(Theme.dark)}
      />
    ) : (
      <LightModeIcon
        size="28"
        className="cursor-pointer text-accent"
        onClick={() => switchTheme(Theme.light)}
      />
    );

  return (
    <div className="h-16 flex w-full items-center justify-between px-4 select-none">
      <div className="text-xl tracking-widest">
        <span className="text-accent">TIME</span>
        <span className="italic">TRAVEL</span>
      </div>
      <div className="flex flex-row">
        <a
          href="https://buymeacoff.ee/sharath"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HeartIcon size="28" className="cursor-pointer text-accent mr-5" />
        </a>
        {!isExtension && (
          <BookmarkIcon
            onClick={() =>
              alert(
                'To bookmark on Mac \n - Press ⌘ + D\n\nTo bookmark on Windows\n - Press Ctrl + D',
              )
            }
            size="28"
            className="hidden sm:block cursor-pointer text-accent mr-5"
          />
        )}
        {themeButton}
      </div>
    </div>
  );
}
