import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import TimeTravelContainer from './components/TimeTravelContainer';
import { getState } from './common/storage';

function App({ isExtension }: { isExtension: boolean }) {
  useEffect(() => {
    const state = getState();
    document.documentElement.setAttribute('theme', state.theme);
  }, []);
  return (
    <div className={`flex flex-col ${isExtension ? '' : 'min-h-screen'}`}>
      <Header isExtension={isExtension} />
      <div className="flex-grow flex flex-col justify-center items-center">
        <TimeTravelContainer isExtension={isExtension} />
      </div>
      {!isExtension && <Footer isExtension={isExtension} />}
    </div>
  );
}

export default App;
