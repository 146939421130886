import React from 'react';
import { Moment } from 'moment-timezone';

interface Props {
  m: Moment;
  zoneName: string;
  onClick: () => void;
}

export default function ({ zoneName, m, onClick }: Props) {
  const time = m.format('hh:mm');
  const amPm = m.format('A');
  const name = zoneName;
  const date = m.format('MMM DD');
  // check if working hours
  const hour = m.hour();
  const isActive = hour >= 9 && hour < 18 ? '' : 'opacity-50';
  return (
    <div
      onClick={onClick}
      className={`text-center w-1/2 sm:w-1/5 my-4 items-center justify-center select-none text-t-primary ${isActive} cursor-pointer animate-opacity`}
    >
      <div className="flex-flex-row">
        <span className="text-3xl">{time}</span>
        &nbsp;<span className="text-sm">{amPm}</span>
      </div>
      <div className="uppercase truncate">{name}</div>
      <div className="text-sm uppercase opacity-75">{date}</div>
    </div>
  );
}
