import axios from 'axios';

export const searchLocations = async (q: string): Promise<any> => {
  if (q.length > 0) {
    const { data } = await axios.get(`https://api.sharath.app/tz?q=${q}`);
    return data;
  } else {
    return [];
  }
};
