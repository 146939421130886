import React from 'react';
import moment from 'moment-timezone';
import Modal from 'react-modal';
import { IoIosAddCircleOutline as AddIcon } from 'react-icons/io';

import { State as TState, getState, saveState } from '../common/storage';
import { round5, convertRange } from '../common/numbers';
import DigitalClock from './DigitalClock';
import { isTouchDevice } from '../common';
import ZonePicker from './ZonePicker';
import { trackEvent } from '../common/analytics';

interface Props {
  isExtension: boolean;
}

interface State {
  state: TState;
  offset: number;
  helpText: string;
  isModalOpen: boolean;
  startInteractionX: number;
}

Modal.setAppElement('#root');

const REFRESH_INTERVAL = 100;

export default class extends React.Component<Props, State> {
  timer: any;
  constructor(props: Props) {
    super(props);
    const state = getState();
    this.state = {
      state,
      offset: 0,
      helpText: !isTouchDevice()
        ? 'Hover over the time'
        : 'Swipe anywhere to time travel',
      isModalOpen: false,
      startInteractionX: -1,
    };
  }

  componentDidMount() {
    trackEvent(
      'zonesCount',
      'TimeTravelContainer',
      this.state.state.zones.length.toString(),
    );
    trackEvent(
      'theme',
      'TimeTravelContainer',
      this.state.state.zones.length.toString(),
    );
    this.timer = setInterval(() => {
      this.forceUpdate();
    }, REFRESH_INTERVAL);
  }

  onMouseOver = (currentTarget: EventTarget & Element, clientX: number) => {
    const rect = currentTarget.getBoundingClientRect();
    const elWidth = rect.width;
    const elStartX = Math.round(rect.left);
    const pageX = Math.round(clientX);
    const mouseX = pageX - elStartX - this.state.startInteractionX;
    const minutes = round5(convertRange(mouseX, [0, elWidth], [0, 60 * 24])); // 5 minute intervals in 24 hours

    this.setState({
      startInteractionX:
        this.state.startInteractionX === -1
          ? mouseX
          : this.state.startInteractionX,
      offset: Math.round(minutes),
      helpText: `Current time: ${moment().format('hh:mm A')}`,
      //helpText: `pageX: ${pageX}, elStartX: ${elStartX}, startInteractionX: ${this.state.startInteractionX} mouseX: ${mouseX}`,
    });
  };

  onMouseOut = () => {
    this.setState({
      offset: 0,
      helpText: '',
      startInteractionX: -1,
    });
  };

  getWidgets() {
    return this.state.state.zones.map((z, i) => {
      let m = moment.tz(z.tz);
      if (this.state.offset !== 0) {
        m = m.minutes(round5(m.minutes())).add(this.state.offset, 'minutes');
      }

      return (
        <DigitalClock
          onClick={() => {
            if (window.confirm(`Do you want to delete ${z.name} ?`)) {
              const zones = [...this.state.state.zones];
              zones.splice(i, 1);
              saveState({
                zones,
              });
              this.setState({
                state: getState(),
              });
              trackEvent('zoneDeleted', 'TimeTravelContainer', z.name);
            }
          }}
          key={`${z.name}-${i}`}
          zoneName={z.name}
          m={m}
        />
      );
    });
  }

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    return (
      <div
        onTouchMove={(e) =>
          this.onMouseOver(e.currentTarget, e.touches[0].clientX)
        }
        onTouchCancel={this.onMouseOut}
        onTouchEnd={this.onMouseOut}
        className={`flex-grow flex flex-col items-center w-full min-h-full md:w-4/5 lg:w-2/3 ${
          this.props.isExtension ? 'mt-4' : 'mt-4 sm:mt-12'
        }`}
      >
        <div
          className={`my-2 sm:my-4 text-accent animate-opacity select-none ${
            this.state.helpText ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {this.state.helpText || <span>&nbsp;</span>}
        </div>
        <div
          onMouseMove={(e) =>
            !isTouchDevice() && this.onMouseOver(e.currentTarget, e.clientX)
          }
          onMouseLeave={!isTouchDevice() ? this.onMouseOut : () => {}}
          className="flex flex-row flex-wrap w-full"
          style={{
            cursor: 'ew-resize',
          }}
        >
          {this.getWidgets()}
        </div>
        <div
          onClick={this.openModal}
          className={`text-t-primary opacity-25 mt-6 hover:opacity-100 hover:text-accent cursor-pointer animate-opacity ${
            this.props.isExtension ? 'mb-10' : ' mb-20'
          }`}
        >
          <AddIcon size={64} />
        </div>
        <Modal
          className="absolute w-full sm:w-1/3 shadow-lg border outline-none sm:rounded-md"
          style={{
            content: isTouchDevice()
              ? {
                  top: 0,
                  left: 0,
                  height: '100%',
                }
              : {
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                },
          }}
          onRequestClose={this.closeModal}
          isOpen={this.state.isModalOpen}
          closeTimeoutMS={150}
        >
          <ZonePicker
            onSelected={(arg) => {
              trackEvent('zoneAdded', 'TimeTravelContainer', arg.name);
              saveState({
                zones: [...this.state.state.zones, arg],
              });
              this.setState({
                state: getState(),
              });

              this.closeModal();
            }}
            closeModal={this.closeModal}
          />
        </Modal>
      </div>
    );
  }
}
