export enum Theme {
  dark = 'dark',
  light = 'light',
}

export interface State {
  theme: Theme;
  zones: {
    name: string;
    tz: string;
  }[];
}

const STORAGE_KEY = 'timeTravel.state';

export function saveState(newState: Partial<State>, firstSave?: boolean) {
  let currentState;
  if (firstSave) {
  } else {
    currentState = getState();
  }

  window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ ...currentState, ...newState }),
  );
}

export function getState(): State {
  const currentState = window.localStorage.getItem(STORAGE_KEY);
  if (currentState) {
    return JSON.parse(currentState) as State;
  } else {
    const defaultState = {
      theme: Theme.light,
      zones: [
        {
          name: 'San Francisco',
          tz: 'America/Los_Angeles',
        },
        {
          name: 'Boston',
          tz: 'America/New_York',
        },
        {
          name: 'London',
          tz: 'Europe/London',
        },
        {
          name: 'Chennai',
          tz: 'Asia/Kolkata',
        },
        {
          name: 'Hong Kong',
          tz: 'Asia/Hong_Kong',
        },
      ],
    };

    saveState(defaultState, true);

    return defaultState;
  }
}
