import React, { Component } from 'react';
import { searchLocations } from '../api/locations';

interface Props {
  closeModal: () => void;
  onSelected: (args: { name: string; tz: string }) => void;
}

interface State {
  isReady: boolean;
  searchTerm: string;
  results: {
    id: string;
    label1: string;
    label2: string;
    timezone: string;
  }[];
}

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReady: true,
      searchTerm: '',
      results: [
        {
          id: '347629',
          label1: 'San Francisco',
          label2: 'California, United States',
          timezone: 'America/Los_Angeles',
        },
        {
          id: '348735',
          label1: 'Boston',
          label2: 'Massachusetts, United States',
          timezone: 'America/New_York',
        },
        {
          id: '328328',
          label1: 'London',
          label2: 'London, United Kingdom',
          timezone: 'Europe/London',
        },
        {
          id: '206671',
          label1: 'Chennai',
          label2: 'Tamil Nadu, India',
          timezone: 'Asia/Kolkata',
        },
        {
          id: '1123655',
          label1: 'Hong Kong',
          label2: 'Sha Tin, Hong Kong',
          timezone: 'Asia/Hong_Kong',
        },
        {
          id: '22889',
          label1: 'Sydney',
          label2: 'New South Wales, Australia',
          timezone: 'Australia/Melbourne',
        },
      ],
    };
  }

  async setSearchTerm(str: string) {
    this.setState({
      searchTerm: str,
    });
    if (str.length > 0) {
      const results = await searchLocations(str);
      this.setState({
        results,
      });
    }
  }

  getResults() {
    const r = this.state.results.map((r, i) => {
      return (
        <div
          className="flex flex-col p-2 border-t-2 cursor-pointer text-t-primary"
          key={i}
          onClick={async () => {
            this.props.onSelected({
              name: r.label1,
              tz: r.timezone,
            });
          }}
        >
          <div className="text-lg">{r.label1}</div>
          <div className="opacity-75">{r.label2}</div>
        </div>
      );
    });
    return <div className="h-full sm:h-64 overflow-scroll">{r}</div>;
  }

  render() {
    const { searchTerm, isReady } = this.state;
    if (!isReady) {
      return (
        <div className=" bg-t-secondary py-5 text-t-primary text-center">
          Loading timezones . . .
        </div>
      );
    }
    return (
      <div className="flex flex-col bg-t-secondary sm:rounded-md h-full">
        <input
          className="flex p-2 outline-none rounded-none text-t-primary bg-t-secondary border border-accent"
          type="text"
          placeholder="Eg. Boston"
          value={searchTerm}
          onChange={(e) => this.setSearchTerm(e.target.value)}
          autoFocus
        />
        {this.getResults()}
        <div
          onClick={this.props.closeModal}
          className="flex p-2 border border-accent text-accent sm:rounded-md justify-center cursor-pointer"
        >
          Cancel
        </div>
      </div>
    );
  }
}
